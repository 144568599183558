import { encodeProperties } from '@exp/exp-utils/helper/url';
import { map, stringify } from '@exp/exp-utils/helper/object';
import { setCookie } from '@exp/exp-utils/helper/cookie';
import { merge as objectMerge } from '@exp/exp-utils/helper/object';

import { sendPageEvent as sendGAPageEvent } from '../../ga/ga';

import { setReferCookie } from '@tcc/shared/src/helpers/visit';

import AddEventHandlerV1 from '@tcc/shared/src/experiments/handlers/addEvent';

// All eventtypes are shortened down to 10 characters.
// Therefore, the below events should not exceed 10 characters.
// https://confluence.godaddy.com/display/CKPT/Non+Interactive+Events
const nonInteractiveEvents = [
  'impression', 'experiment', 'load', 'apicall',
  'split-test', 'redux', 'change', 'custom',
  'impress', 'blur', 'view', 'applog', 'show',
  'success', 'perf', 'loaded', 'clicktaleu', 'notify',
  'clicktale', 'consent', 'timing'];

class AddEventHandlerTcc extends AddEventHandlerV1 {

  preProcess() {
    super.preProcess();
    this._setNonInteraction();
  }

  _setNonInteraction() {
    this.nonInteraction = nonInteractiveEvents.indexOf(this.data.ALL.type.toLowerCase()) !== -1;
    if (!this.nonInteraction) {
      setReferCookie(
        this.pageEvent.get('e_id'),
        this.pageEvent.get('usrin'),
        this.pageEvent.get('tcode'),
        this.pageEvent.get('ci'),
        this.pageEvent.get('tms'),
        // Client-side session identifier.
        this.pageEvent.get('corrid'));
    }
  }

  process() {
    super.process({
      GA: () => { this._handleGA(); }
    });
  }

  _handleGA() {
    // Send 'Event' record to Google Analytics
    sendGAPageEvent(this.pageEvent.get('e_id'), this.pageEvent.getProperties(), objectMerge(this.tData.getProperties(), this.extras), this.nonInteraction);
  }

  _setReferCookie(eid, usrin, tcode, ci, tms, corrid) {
    const dataProps = {};
    // delete refer cookie
    setCookie('tcc_refer', '', -1, '/');
    if (eid) {
      dataProps.refer_e_id = eid;
    }
    if (usrin) {
      dataProps.refer_usrin = usrin;
    }
    if (tcode) {
      dataProps.refer_tcode = tcode;
    }
    if (ci) {
      dataProps.refer_ci = ci;
    }
    if (tms) {
      dataProps.refer_tms = tms;
    }
    if (corrid) {
      dataProps.refer_corrid = corrid;
    }
    map(dataProps, () => {
      const encodedProperties = encodeProperties(dataProps);
      setCookie('tcc_refer', stringify(encodedProperties, '&', '='), 5, '/');
      return true;
    });
  }
}

export default AddEventHandlerTcc;
