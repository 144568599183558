import AddPageRequestV1 from '@tcc/shared/src/experiments/handlers/addPageRequest';

import { sendPageView as sendGaPageView } from '../../ga/ga';
import { merge } from '@exp/exp-utils/helper/object';

class AddPageRequestTcc extends AddPageRequestV1 {
  process() {
    super.process({
      GA: (input) => { this._handleGA(input); }
    });
  }

  _handleGA(input) {
    // Send 'Page View' record to Google Analytics
    sendGaPageView(input.virtual_path, merge(this.tData.getProperties(), this.extras));
  }
}

export default AddPageRequestTcc;
