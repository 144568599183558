
import { error } from '@exp/exp-utils/helper/logger';
import { merge as objectMerge } from '@exp/exp-utils/helper/object';
import { getClickEventData as getBrowserClickEventData } from '@exp/exp-utils/helper/browser';
import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

import { createEventProperties, addEventProperties, createTData } from '../../../../shared/src/traffic/eventProperties';
import { sendEvent } from '../../../../shared/src/traffic/eventSend';

class AddEventHandler extends SchemaHandler {
  process(sinkFnMap) {
    // Don't process event if event contains invalid characters
    if (!this._validateEIDCharacters(this.pageEvent.get('e_id'))) return;

    // Validate EID formating after ensuring event is not going to be dropped.
    this._validateEIDFormat(this.pageEvent.get('e_id'));

    super.process(
      objectMerge({
        EVENT_SVC: () => { this._handleEventSvc(); }
      }, sinkFnMap));
  }

  _handleEventSvc() {
    sendEvent(this.pageEvent.getProperties(), '/pageEvents.aspx', 'GET');
  }

  _validateEIDFormat(eId) {
    const validEID = /^([a-z0-9_\-]+)\.([a-z0-9_\-]+)\.(([a-z0-9_\/\-]+)\.)?([a-z0-9_\/\-]+)\.([a-z0-9_\-]+)\.([a-z0-9_\-]+)$/g;
    const isValid = eId && eId.match(validEID) && eId.length <= 500;
    if (!isValid) {
      error('Invalid e_ID naming syntax for', eId, 'See', 'https://confluence.godaddy.com/display/CKPT/Event+Naming+and+Formats');
    }
    return !!isValid;
  }

  _validateEIDCharacters(eId) {
    const invalidCharacters = /[^a-z0-9\_\/\.\-]/g;
    const hasInvalidCharacters = eId && eId.match(invalidCharacters);
    if (hasInvalidCharacters) {
      error(`Invalid characters in e_ID: ${eId} The event has been DROPPED. See https://confluence.godaddy.com/display/CKPT/Event+Naming+and+Formats`);
    }

    return !hasInvalidCharacters;
  }

  preProcess() {
    this.tData = createTData(this.data);

    // Some pages might still be using the deprecated property "properties" as custom properties
    this.tData.merge(this.data.ALL.properties);

    this.pageEvent = createEventProperties('page.event', this.tData);
    addEventProperties(this.pageEvent, this.data.ALL.type);

    // We will only accept the dom_element and dom_event in the v1 schema
    this.pageEvent.merge(getBrowserClickEventData(this.data.ALL.dom_element, this.data.ALL.dom_event));

    // in the v2 schema, the click event data is supplied as a property
    this.pageEvent.merge({
      href: this.data.ALL.href,
      tcode: this.data.ALL.tcode,
      tms: this.data.ALL.tms,
      ci: this.data.ALL.ci
    });

    // override dom element attributes with those passed in.
    if (this.data.ALL.eid) {
      this.pageEvent.set('e_id', this.data.ALL.eid);
    }

    // Use event_label provided from schema (if provided) before one provided in tdata (if provided)
    const eventLabel = this.data.ALL.event_label || this.tData.getProperties().event_label;
    if (eventLabel) {
      this.pageEvent.set('event_label', eventLabel);
    }

    // Convert EID to lower case characters
    this.pageEvent.set('e_id', this.pageEvent.get('e_id').toLowerCase());

    // Merge datalayer extras, which includes an identifier to
    // correlate the hit between downstream systems (hit_id)
    this.pageEvent.merge(this.extras);
  }
}

export default AddEventHandler;
