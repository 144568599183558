import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';
import { merge } from '@exp/exp-utils/helper/object';

import trackingValues from '@tcc/shared/src/traffic/trackingValues';

import experimentTracker from '../experimentTracking';

const eidPrefix = 'traffic.tcc.instrumentation';

class AddExperimentAssignmentHandler extends SchemaHandler { // eslint-disable-line id-length

  preProcess() {}

  process() {
    super.process({
      ALL: (input) => { this._handle(input); }
    });
  }

  _handle(input) {
    // call the 'add_event' schema
    this.schemaHelper.handleSchema('add_event', undefined, 'v1', {
      type: 'experiment',
      eid: `${eidPrefix}.experiment.add_experiment`,
      // Merge to properties rather than custom_properties UNTIL getVariantForExperiment is ripped out of TCC
      properties: merge({
        experiment_type: this.schemaType || input.experiment_type,
        experiment_id: input.experiment_id,
        experiment_source: input.experiment_source,
        variant_id: input.variant_id,
        content_id: input.content_id
        // Custom properties are only mapped to the event service sink rather than GA's input, however,
        // we reuse them for GA here as part of building custom dimensions.
      }, this.sinks.includes('EVENT_SVC') ? this.data.EVENT_SVC.custom_properties : {})
    },
    this.extras,
    // this schema's sinks will override add_event's sinks
    this.sinks);

    experimentTracker.set(input.experiment_id, input.variant_id);
    trackingValues.set('experiments', experimentTracker.getProperties());
  }
}

export default AddExperimentAssignmentHandler;
