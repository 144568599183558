import { createEventProperties, addEventProperties, createTData } from '../../../../shared/src/traffic/eventProperties';
import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

import { sendEvent } from '../../../../shared/src/traffic/eventSend';

class AddPerfHandler extends SchemaHandler {

  process() {
    super.process({
      EVENT_SVC: () => { this._handleEventSvc(); }
    });
  }

  _handleEventSvc() {
    sendEvent(this.pageEvent.getProperties(), '/b.aspx', 'GET');
  }

  preProcess() {
    this.pageEvent = createEventProperties('page.log', createTData(this.data));

    addEventProperties(this.pageEvent, this.data.ALL.type);

    // Hard vs Soft navigation (normal page request vs virtual page request)
    this.pageEvent.set('nav_type', this.data.ALL.nav_type);

    // This handler is reused by multiple perf interfaces and is kept
    // around for legacy functionality (primarily SPAs)
    if (typeof this.data.ALL.properties === 'object') {
      this.pageEvent.merge(this.data.ALL.properties);
    }

    // Merge datalayer extras, which includes an identifier to
    // correlate the hit between downstream systems (hit_id)
    this.pageEvent.merge(this.extras);
  }
}

export default AddPerfHandler;
